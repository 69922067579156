import './styles.scss';
import { carouselUserDefinedResponseHandler } from './carouselResponse';

/**
 * Injects a web chat instance into the page.
 */
async function addWebChat() {
  // This is the standard web chat configuration object. You can modify these values with the embed code for your
  // own assistant if you wish to try this example with your assistant. You can find the documentation for this at
  // https://web-chat.global.assistant.watson.cloud.ibm.com/docs.html?to=api-configuration#configurationobject.
  const options = {
    integrationID: "49e2dbd8-c890-4ce6-9f44-5e908b7bd8e7",
    region: "eu-de",
    serviceInstanceID: "273e780d-5c34-4f8a-8b8a-58c3697f9108",
    onLoad: webChatOnLoad,
    //debug: true
  };

  window.watsonAssistantChatOptions = options;

  const script = document.createElement('script');
  const version = options.clientVersion || 'latest';
  script.src = `https://web-chat.global.assistant.watson.appdomain.cloud/versions/${version}/WatsonAssistantChatEntry.js`;
  document.head.appendChild(script);
}

/**
 * This function is called when web chat has been loaded and is ready to be displayed.
 */
async function webChatOnLoad(instance) {
  instance.updateLauncherAvatarURL('https://tars-file-upload.s3.amazonaws.com/gUAfIC/9bc408a2ead229af348ff28711f17df0--New-Tar.gif');

  instance.on({ type: 'identityTokenExpired',
    handler: async function(event) {

      let jwtFromServer = await fetch(process.env.EXTERNAL_URL + '/api/web-chat/createJWT');
      if (!jwtFromServer.ok) {
        throw new Error('Network response was not ok');
      }
      jwtFromServer = await jwtFromServer.json();
      console.log(new Date() + ": " + jwtFromServer);
      
      event.identityToken = jwtFromServer.jwtString;
    }});
    

  instance.on({
    type: 'userDefinedResponse',
    handler: (event, instance) => {
      // The "user_defined_type" property is just an example; it is not required. You can use any other property or
      // condition you want here. This makes it easier to handle different response types if you have more than
      // one user defined response type.
      if (event.data.message.user_defined && event.data.message.user_defined.user_defined_type === 'carousel') {
        carouselUserDefinedResponseHandler(event, instance);
      }
    },
  });
  await instance.render();
}

// Launch web chat as soon as this script has loaded.
addWebChat();

